@layer components {
  .ui--select {
    @apply relative select-none;
    position: relative;
    user-select: none;

    &-field {
      @apply grid h-10 cursor-pointer grid-flow-col items-center gap-2 rounded-lg bg-black/75 px-3 text-neutral-200;

      &:hover {
        @apply bg-black/50;
      }

      .label {
        @apply text-xs font-thin text-white/75;
      }

      .value {
        @apply px-1 text-sm capitalize text-white/80;
      }

      .icon {
        @apply text-xs;
      }
    }

    &-dropdown {
      @apply absolute grid gap-1 overflow-y-auto overflow-x-hidden rounded-lg bg-white p-1;

      top: 110%;
      right: 0;
      z-index: 4;
      min-width: 140px;
      max-height: 320px;
      transition: all 200ms ease;

      &:not(.active) {
        @apply pointer-events-none -translate-x-1 opacity-0;
      }

      .li {
        @apply flex h-8 cursor-pointer items-center justify-between gap-2 px-2 text-sm font-semibold capitalize text-black;

        &:hover {
          @apply rounded-lg bg-black/10;
        }

        &.selected {
          &::after {
            content: '';
            border-radius: 50%;
            box-shadow: 0 0 4px black;
            @apply block h-2 w-2 bg-black;
          }
        }
      }
    }
  }
}
