@layer components {
  .btn {
    @apply relative grid grid-flow-col items-center justify-center gap-1 rounded-md leading-5 duration-150 ease-out;

    .text {
      @apply font-medium;
    }

    &-text {
      &:hover {
        .text {
          @apply underline;
        }
      }
    }

    &-primary {
      @apply bg-white/90 text-neutral-800;

      &:hover {
        @apply bg-white;
      }
    }

    &-secondary {
      @apply bg-neutral-50 ring-1 ring-neutral-400 ring-offset-0;

      &:hover {
        @apply bg-neutral-100;
      }
    }

    &:disabled,
    &.disabled {
      @apply cursor-not-allowed opacity-50;
      filter: grayscale(60%);
    }
  }
}
