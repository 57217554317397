// SET global classes.
.ui- {
  &-app {
    &-container {
      transition: opacity 640ms ease-in;

      // &:not(.ready) {
      //   opacity: 0;
      // }

      .mirror {
        background-color: rgba(white, 0.04);
        backdrop-filter: blur(2rem);
        @apply rounded-lg;

        &.use-ring {
          box-shadow: 0 0 0 1px rgba(white, 0.08);
        }
      }
    }
  }

  &-router {
    &-view {
      max-width: 1440px;
      @apply mx-auto min-h-screen px-8;

      &.logged-in {
        grid-template-columns: auto 1fr;
        @apply grid grid-flow-col items-start gap-10;
      }

      & > div {
        @include class-endWith('-container') {
          @apply py-8;
        }
      }

      .page-header {
        z-index: 10;

        @apply sticky -top-2 my-8 p-8;

        .hr {
          // &-horizontal {}

          &-vertical {
            width: 1px;
            @apply mx-2 block h-full bg-neutral-800;
          }
        }
      }

      .page-nav {
        @apply grid grid-flow-row gap-8;

        &-rows {
          @apply grid items-center gap-4;

          &.rtl {
            @apply grid-flow-col justify-start;
          }

          &.ltr {
            @apply grid-flow-col justify-end;
          }

          .h3 {
            @apply select-none text-2xl font-bold;
          }

          .desc {
            @apply mt-2 select-none text-xs italic text-white/75;
          }

          .btn {
            @apply h-full min-h-9 px-4;

            .icon {
              @apply text-sm;
            }

            .text {
              @apply text-base;
            }
          }
        }

        // &-columns {}

        img.construction {
          @apply mx-auto block object-contain object-center;
        }

        input.search {
          width: 320px;
          @apply h-full rounded-lg bg-black/75 px-4 text-white/75 placeholder:text-xs placeholder:text-white/30 hover:bg-black/50 focus:bg-black/50;
        }
      }
    }
  }

  &-lorem {
    &-loading {
      @apply select-none px-4 py-8 text-center capitalize;
    }

    &-empty {
      @apply rounded-lg border border-solid border-neutral-800 bg-neutral-900/10 px-4 py-8;

      .content {
        @apply grid select-none justify-center gap-2 text-center text-neutral-200;
      }

      .icon {
        @apply text-4xl;
      }

      .text {
        @apply font-medium;
      }
    }
  }

  &-modal {
    .modal-context {
      box-shadow:
        0 0 0 1px rgba(white, 0.1),
        0 0 0 8px rgba(white, 0.04);
      @apply rounded-xl bg-neutral-950/90;
    }
  }
}
