@layer components {
  .ui--input {
    &-label {
      .icon {
        border-right: 1px solid transparent;
        @apply border-neutral-700;
      }

      .text {
        @apply font-semibold;
      }
    }

    &-field {
      input {
        & ~ .icon {
          &.is-text {
            @apply text-neutral-200;
          }
        }

        @include attr('type', 'password') {
          @apply text-lg font-bold tracking-[3px];
        }

        @include attr('type', ('number', 'search')) {
          appearance: none;
          -moz-appearance: textfield;

          &::-webkit-outer-spin-button,
          &::-webkit-inner-spin-button {
            appearance: none;
            -webkit-appearance: none;
          }
        }
      }
    }
  }
}
