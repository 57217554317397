.ui--trans {
  &-container {
  }

  &-lorem {
    .table {
      .tbody {
        .td {
          .REFILL {
            @apply text-indigo-300;
          }

          .PENDING {
            @apply text-yellow-400;
          }

          .PURCHASE {
            @apply text-blue-400;
          }

          .SUCCESS {
            @apply text-green-400;
          }

          .REJECTED,
          .FAILED {
            @apply text-red-500;
          }

          .CANCELED {
            @apply text-neutral-300;
          }
        }
      }

      .tr {
        gap: 0.5rem;
        grid-template-columns: 60px repeat(2, 180px) repeat(2, 1fr) 100px 32px;
      }
    }
  }
}
