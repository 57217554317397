@layer components {
  .ui--table {
    @apply w-full;

    .thead {
      border-bottom: 1px solid transparent;
      @apply mb-3 select-none border-white/20;

      .td {
        @apply text-xs font-light capitalize italic text-white/60;
      }
    }

    .tbody {
      @apply grid gap-1;

      .tr {
        &:not(.disabled) {
          &:hover {
            background-color: rgba(white, 0.04);
            box-shadow: 0 0 0 1px rgba(white, 0.08);
            backdrop-filter: blur(8rem);

            @apply rounded-lg;
          }
        }

        &.disabled {
          @apply cursor-not-allowed opacity-50;
        }
      }
    }

    .tr {
      @apply grid min-h-12 grid-flow-col items-center justify-between gap-2 px-3;
    }

    .td {
      .btn {
        &-action {
          @apply h-8 w-8 text-white/80 hover:text-white;
        }
      }
    }
  }
}
