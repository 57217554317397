.ui--lobby {
  &-container {
  }

  &-body {
    grid-template-columns: 1fr auto;
    @apply grid items-start gap-8;
  }

  &-system {
    .li {
      @apply pb-4;
      border-bottom: 1px solid rgba(white, 0.08);

      &:last-child {
        padding-bottom: 0;
        border-bottom: none;
      }
    }
  }

  &-three {
    .columns {
      @apply grid gap-4;
    }

    .head {
      grid-template-columns: auto 1fr auto;
    }
  }

  &-trans {
    @apply grid min-h-56 gap-4;
    grid-template-rows: auto 1fr auto;

    .body {
      border-top: 1px solid transparent;
      @apply border-t-white/10 pt-2;
    }

    .table {
      .tr {
        grid-template-columns: 40px 1.5fr repeat(3, 1fr) 72px;
        @apply gap-2;

        &:hover {
          @apply cursor-pointer;
        }
      }
    }
  }

  &-chart {
    @apply grid min-h-48 gap-4;
    grid-template-rows: auto 1fr auto;
  }
}
