@layer components {
  .ui--navbar {
    height: calc(100vh - 4rem);
    border-right: 1px solid transparent;
    @apply w-56 border-r-white/5 py-8 pr-8;

    &-container {
      grid-template-rows: auto 1fr auto;

      .ul {
        backdrop-filter: blur(4rem) !important;

        @apply grid overflow-hidden;
      }

      .router-link {
        grid-template-columns: 26px 1fr 12px;

        @apply relative h-14 justify-start rounded-none px-4 text-white/60;

        &:hover {
          color: white;
          background-color: rgba(white, 0.015);
        }

        .bi-hash {
          @apply text-base;
        }

        &-active {
          color: white;
          background-color: rgba(white, 0.025);

          &::after {
            content: '';
            @apply h-2 w-3 rounded-md bg-white shadow-[0_0_8px] shadow-white;
          }
        }
      }
    }
  }
}
