/**
 * This injects Tailwind's base styles and any base styles registered by
 * plugins.
 * 
 * @tailwind base;
 */
@import 'core/mixin';
@import 'core/reset';
@import 'core/global';

/**
 * This injects Tailwind's component classes and any component classes
 * registered by plugins.
 */
@tailwind components;
@import 'components/button';
@import 'components/input';
@import 'components/loader';
@import 'components/modal';
@import 'components/navbar';
@import 'components/notice';
@import 'components/paginate';
@import 'components/select';
@import 'components/table';
@import 'pages/lobby.scss';
@import 'pages/record.scss';
@import 'pages/trans.scss';

/**
 * This injects Tailwind's utility classes and any utility classes registered
 * by plugins.
 */
@tailwind utilities;

/**
 * Use this directive to control where Tailwind injects the hover, focus,
 * responsive, dark mode, and other variants of each class.
 *
 * If omitted, Tailwind will append these classes to the very end of
 * your stylesheet by default.
 */
@tailwind variants;
