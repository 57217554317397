.ui--record {
  &-container {
  }

  &-lorem {
    .table {
      .tbody {
        .td {
        }
      }

      .tr {
        gap: 0.5rem;
        grid-template-columns: 60px 1fr 100px 32px;
      }
    }
  }
}
