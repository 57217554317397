@layer components {
  .ui--loader {
    &-rainbow {
      background-image: linear-gradient(
        to right,
        #ef5350,
        #f48fb1,
        #7e57c2,
        #2196f3,
        #26c6da,
        #43a047,
        #eeff41,
        #f9a825,
        #ff5722
      );
    }
  }
}
