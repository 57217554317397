@layer components {
  .ui--paginate {
    @apply grid select-none grid-flow-col justify-start gap-4 px-2 pt-4;

    &-message {
      @apply self-center text-xs italic text-neutral-300;
    }

    &-actions {
      @apply flex items-center gap-1;

      .btn {
        @apply h-9 w-8 text-neutral-300;

        &:not(:disabled) {
          &:hover {
            @apply text-white;
          }
        }

        .icon {
          @apply text-lg;
        }

        &-prev {
        }

        &-next {
        }
      }
    }
  }
}
