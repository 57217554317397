@layer components {
  .ui--modal {
    &.none {
      @apply pointer-events-none;
    }

    &-wrapper {
      backdrop-filter: blur(2px);
      @apply fixed inset-0 z-40 grid items-start overflow-x-hidden bg-black/75 px-8 py-16;

      &.enter-active,
      &.enter-done {
        @include class-contains('-container') {
          @apply translate-y-0 opacity-100;
        }
      }

      // &.is-default {}

      &.is-dialog {
        @include class-endWith('modal-container') {
          @apply min-w-[320px] rounded-xl bg-neutral-900 px-8 pb-6 pt-8;
          box-shadow: 0 10px 20px rgba(#1b2430, 0.08);
        }

        @include class-endWith('modal-footer') {
          @apply grid grid-flow-col justify-end gap-4;

          .btn {
            @apply h-8 w-auto min-w-20;

            &:focus {
              outline: 1px solid silver;
              outline-offset: 1px;
            }

            .text {
              @apply text-xs font-bold capitalize;
            }
          }
        }
      }
    }

    &-container {
      transition: all 200ms ease;
      transform: translate(0, -10px);

      @apply relative mx-auto min-h-64 opacity-0;
    }
  }
}
